import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import cx from 'classnames';

import { BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import BookHeader from '../BookHeader';
import BookSidebar from '../BookSidebar';
import Chapter from '../Chapter';
import Completed from '../Completed';
import AddPack from '../AddPack';
import AboutPromoBook from '../AboutPromoBook';
import { AuthContext } from '../../../../services/auth/AuthProvider';

import styles from './BookHolder.module.scss';
import BookSettings from '../BookSettings';

import { parse } from 'query-string';
import { useLocation, useEffectOnce } from 'react-use';
import BookParentLetters from '../BookParentLetters'
import PrintableStudentsList from '../../../Class/scenes/Students/components/PrintableStudentsList';

const BookHolder = ({ bookData }) => {
    const location = useLocation();
    const { currentUser } = useContext(AuthContext);

    const [selectedChapter, setSelectedChapter] = useState(() => {
        const printableChapter = parse(location.search).printStudentsChapter;
        if (printableChapter && !isNaN(printableChapter)) {
            return printableChapter;
        }
        if (bookData.status >= BOOK_STATUS.completed) {
            return -1
        } else {
            // const firstChapter = bookData.chapters[bookData.isSingleChapter || bookData.classPack.isWritingActivity ? 0 : 1];
            const firstChapter = bookData.chapters[bookData.isSingleChapter ? 0 : 1];

            if (firstChapter.status === CHAPTER_STATUS.new) {
                return -2
            } else {
                return bookData.currentChapter || 0

                // return (bookData.classPack.isWritingActivity ? 0 : bookData.currentChapter) || 0
            }
        }
    });


    const [projected, setProjected] = useState(false);
    const [showPrintableList, setShowPrintableList] = useState(false);


    const bookStatusRef = useRef(bookData.status);
    const currentChapterRef = useRef(bookData.currentChapter);


    useEffect(() => {
        if (bookData.status !== bookStatusRef.current) {
            if (bookStatusRef.current === BOOK_STATUS.started) {
                setSelectedChapter(-1);
            }
            bookStatusRef.current = bookData.status;
            // currentChapterRef.current = bookData.currentChapter;

            return;
        }
        if (bookData.currentChapter !== currentChapterRef.current) {
            setSelectedChapter(bookData.currentChapter);
            currentChapterRef.current = bookData.currentChapter;
        }
    }, [bookData, currentChapterRef, bookStatusRef, setSelectedChapter]);

    if (parse(location.search).printParentLetters) {
        return <BookParentLetters classId={bookData.studentsClass.id} bookData={bookData} />
    }


    if (showPrintableList) {
        return <PrintableStudentsList classId={bookData.studentsClass.id} school={currentUser.school} exitPrintMode={() => setShowPrintableList(false)} />

    }

    return (
        // <div className={cx(styles.book, { [styles.bookNoSide]: bookData.classPack.isWritingActivity })}>
        <div className={cx(styles.book)}>

            {
                projected ? <p>Projected</p> :
                    (
                        <Fragment>
                            <BookHeader
                                book={bookData}
                                classId={bookData.studentsClass.id}
                                title={bookData.title || bookData.bookTemplate?.name}
                                // subtitle={bookData.classPack.isWritingActivity ? null : bookData.classPack.bookTemplateCategory?.name}
                                // subtitle={bookData.classPack?.bookTemplateCategory?.name}
                                writingActivity={false}
                            // writingActivity={bookData.classPack.isWritingActivity && bookData}
                            />
                            <div className={styles.bookContent}>
                                {/* {!bookData.classPack.isWritingActivity && <BookSidebar book={bookData} selectedChapter={selectedChapter >= 0 ? bookData.chapters[selectedChapter] : selectedChapter} setSelectedChapter={setSelectedChapter} />} */}

                                {<BookSidebar book={bookData} selectedChapter={selectedChapter >= 0 ? bookData.chapters[selectedChapter] : selectedChapter} setSelectedChapter={setSelectedChapter} />}
                                {
                                    selectedChapter === -2 ?
                                        <BookSettings
                                            book={bookData}
                                            projected={projected}
                                            setProjected={setProjected}
                                            setSelectedChapter={setSelectedChapter}
                                        /> :
                                        // selectedChapter !== -1 || (bookData.status >= BOOK_STATUS.completed && bookData.classPack.isWritingActivity) ?
                                        selectedChapter !== -1 ?
                                            <Chapter
                                                // chapter={(bookData.classPack.isWritingActivity && bookData.chapters[0]) || bookData.chapters[selectedChapter]}
                                                chapter={bookData.chapters[selectedChapter]}

                                                nextChapter={bookData.chapters[selectedChapter + 1]}
                                                projected={projected}
                                                setProjected={setProjected}
                                                book={bookData}
                                                setShowPrintableList={setShowPrintableList}
                                            /> :
                                            <Completed book={bookData} />
                                }
                            </div>
                        </Fragment>
                    )
            }
        </div>
    )
}

export default BookHolder;
