import JSZip from 'jszip';
import axios from 'axios';

export const onDownloadFiles = async (urls, setProgress) => {
    const zip = new JSZip();
    const folder = zip.folder("downloaded_pdfs");

    let completedDownloads = 0;

    await Promise.all(urls.map(async (url, index) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    console.log('progressEvent', progressEvent);
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(`Downloading file ${index + 1} of ${urls.length}: ${percentCompleted}%`);
                }
            });
            const contentDisposition = response.headers['content-disposition'];
            let filename = url.split('/').pop();

            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }

            // Add the file to the zip
            folder.file(filename, response.data);

            completedDownloads += 1;
            setProgress(`Downloaded ${completedDownloads} of ${urls.length} files.`);

        } catch (error) {
            console.error(`Failed to download file from ${url}:`, error);
        }
    }));

    setProgress('Creating zip file...');

    zip.generateAsync({ type: "blob" })
        .then((content) => {
            const downloadUrl = window.URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'files.zip');
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
            setProgress('');
        });
}


