import React, { Fragment, useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { Book, CheckCircle, ChevronDown, ChevronUp, File, PlusCircle, Search } from 'react-feather';
import cx from 'classnames'
import { Link } from '@reach/router';
import moment from 'moment';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { CURRENT_PROMO_QUERY } from '../PromoBook/services/graphql';

import PackListItem from './PackListItem';
import styles from './PackList.module.scss';
import styles2 from '../PackList/PackListItem/PackListItem.module.scss';

import Tabs from '../../../../components/Tabs';
import Tab from '../../../../components/Tab';
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import { Button } from '@axeedge/go-teacher-components';

const NEW_TO_OLD = 'Date New To Old';
const OLD_TO_NEW = 'Date Old To New';

const PacksList = ({ classId, packs }) => {
    const { currentUser } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState(NEW_TO_OLD);


    const { data } = useQuery(CURRENT_PROMO_QUERY);
    const [promoData, setPromoData] = useState(null);

    useEffect(() => {
        if (data && data.currentPromos && data.currentPromos.length > 0) {
            setPromoData(data.currentPromos[0]);
        }
    }, [data])


    const getCompletedPacks = withSearch => {
        const completedPacks = [];
        packs.forEach(pack => {
            if (pack.book && pack.book.status >= BOOK_STATUS.completed) {
                if (!withSearch || withSearch === '') {
                    completedPacks.push(pack);
                } else {
                    if (pack.book.title.toLowerCase().indexOf(withSearch && withSearch.toLowerCase()) !== -1) {
                        completedPacks.push(pack);
                    }
                }
            }
        });
        return completedPacks;
    }
    const [completedPacks, setCompletedPacks] = useState(getCompletedPacks());
    const [showCompleted, setShowCompleted] = useState(false);

    const doSearch = newSearchTerm => {
        setSearchTerm(newSearchTerm);
        setCompletedPacks(getCompletedPacks(newSearchTerm));
    }

    const sortPacks = () => {
        const sortedPacks = _.sortBy(completedPacks, pack => {
            return pack.book.completedAt
        });
        if (sortOrder === NEW_TO_OLD) {
            return _.reverse(sortedPacks);
        }
        return sortedPacks;
    }

    // if (promoData && showPromo) {
    //     return <PromoBook classId={classId} currentPromo={promoData} closePromo={() => setShowPromo(false)} /> 
    // }

    // const renderPromos = (promoData) => {
    //     const promoBanners = [];
    //     promoData.forEach(promo => {
    //         if (!packs.find(pack => pack.bookTemplate && pack.bookTemplate.id === promo.id)) {
    //             promoBanners.push(
    //                 <Link key={promo.id} to={`/promo/${promo.id}`} state={{ classId: classId }} className={styles.promoBanner}>
    //                     <ImageFadeIn className={styles.promoBannerImg} src={promo.coverUrl} />
    //                     <div className={styles.promoBannerBody}>
    //                         <h2>{promo.name}</h2>
    //                         <p>{promo.description}</p>
    //                     </div>
    //                     <div className={styles.promoBannerInfo}>
    //                         <p>More info</p>
    //                     </div>
    //                 </Link>
    //             );
    //         }
    //     });
    //     return promoBanners;
    // }

    return (
        <Fragment>
            <div className='card card-mobile u-m-base-2'>
                <Tabs>
                    <Tab selected={!showCompleted} onClick={() => setShowCompleted(false)}>Writing Activities</Tab>
                    <Tab selected={showCompleted} onClick={() => setShowCompleted(true)}>Completed</Tab>
                </Tabs>
            </div>

            {
                !showCompleted ? (
                    <div className='row'>
                        {promoData && !packs.find(pack => pack.bookTemplate && pack.bookTemplate.id === promoData.id) && (
                            // <PromoTemplatePanel classId={classId} currentPromo={promoData} />
                            <Link to={`/promo/${promoData.id}`} state={{ classId: classId }} className={cx(styles2.pack, 'col-lg-4 col-md-6 btn-reset u-text-center')}>
                                <div className='card card-hover'>
                                <div className={styles2.packType}>
                                    <Book />
                                </div>
                                <div className={styles2.leaversContent}>
                                    {promoData.name}
                                </div>
                                <div className='card-footer'>
                                    <p className={styles2.packSubTitle}>Setup & Start</p>
                                </div>
                                </div>
                            </Link>
                        )}

                        {
                            !!packs.length && packs.map(pack => {
                                if (pack.book && pack.book.status >= BOOK_STATUS.completed) {
                                    return null;
                                }
                                return <PackListItem key={pack.id} classId={classId} pack={pack} />
                            })
                        }
                        {currentUser.school.canAccessGoWriteActivities &&
                            <Link to={`/task/new/${classId}`} className={cx(styles.addTaskBtn, 'col-lg-4 col-md-6')}>
                                <div className='card card-hover'>
                                    <div className={styles.addTaskBtnContent}>
                                        <PlusCircle className={styles.addTaskBtnIcon} />Writing Task
                                    </div>
                                </div>
                            </Link>
                        }
                    </div>
                ) : (
                    <div className='card card-mobile'>
                        <div className={styles.utilities}>
                            <div className={styles.utilitiesSearch}>
                                <input className={styles.utilitiesSearchInput} type='text' placeholder='Search Activities' onChange={e => doSearch(e.target.value)} value={searchTerm} />
                                <Search className={styles.utilitiesSearchIcon} />
                            </div>
                            <Button className={styles.utilitiesSortButton} outline onClick={() => setSortOrder(sortOrder === NEW_TO_OLD ? OLD_TO_NEW : NEW_TO_OLD)}>
                                <span className='u-m-right-1'>{sortOrder}</span>{sortOrder === NEW_TO_OLD ? <ChevronDown /> : <ChevronUp />}
                            </Button>
                        </div>
                        {
                            completedPacks.length === 0 ? (
                                <p className='u-m-top-2'>{getCompletedPacks(false).length === 0 ? 'There are no completed packs' : 'No packs match your search criteria'}</p>
                            ) : sortPacks().map(pack => {
                                return (
                                    <Link to={`/book/${pack.book.id}`} className={styles.packCompleted} key={pack.id}>
                                        <div className={styles.packCompletedIcon}>
                                            {/* {pack.isWritingActivity ? <File size={17} /> : <Book size={17} />} */}
                                            <Book size={17} />
                                        </div>
                                        <p className={styles.packCompletedTitle}>{pack.book.title}</p>
                                        <p className={styles.packCompletedDate}><CheckCircle className='u-m-right-1' size={18} /> {pack.book.completedAt ? moment(pack.book.completedAt).format('D MMM, YYYY') : ''}</p>
                                    </Link>
                                )
                            })
                        }
                    </div>
                )
            }
        </Fragment>
    )
}

export default PacksList;
