import { gql } from "apollo-boost";
import { classBookQuery } from "../../../../../services/constants";

export const GET_DIGITAL_CLASS_ORDER = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        description
        students {
            id
            firstName
            lastName
            name
            token
            email
            loginCode
            expiredToken
            storeCode
        }
        books {
           ${classBookQuery}
        }
        school {
            id
            name
            country
        }
    }
}`;