import React from 'react';
import ImageFadeIn from "react-image-fade-in";
import { Link } from '@reach/router';
import cx from 'classnames';
import { itemDateColor } from '../../../../../services/utils';
import wtrLogo from '../../../../../images/wtr-logo.png';

import styles from './PackListItem.module.scss';
import { Book, File } from 'react-feather';
import { BOOK_STATUS } from '@axeedge/go-shared-utils';

const PackListItem = ({ pack }) => {
    return (
        <Link to={pack.isWritingActivity && pack.book && pack.book.status < BOOK_STATUS.started ? `/task/${pack.book.id}` : pack.book ? `/book/${pack.book.id}` : `/pack/${pack.id}`} className={cx(styles.pack, 'col-lg-4 col-md-6')}>
            <div className='card card-hover'>
                <div className={styles.packType}>
                    {pack.isWritingActivity ? <File /> : <Book />}
                </div>
                {pack.book && pack.book.title.indexOf('Leavers') > -1 ? (
                    <div className={styles.leaversContent} >
                        {pack.book.title} 
                    </div>
                ) : (
                    <div className={cx(styles.packContent, pack.bookTemplate?.isBeeTemplate && styles.bee)} style={pack.bookTemplate?.isBeeTemplate ? {} : {background: itemDateColor(pack.createdAt) }}>
                        {!pack.bookTemplate?.isBeeTemplate &&
                            <>{pack.isPromo && pack.book ?
                                <h2 className={styles.packTitle}>{pack.book.title}</h2> :
                                <h2 className={styles.packTitle}>{pack.isWritingActivity ? pack.book.title : pack.bookTemplateCategory ? pack.bookTemplateCategory.name : 'Your Class Book'} </h2>
                            }
                            </>
                        }
                    </div>
                )}
                <div className='card-footer'>
                    <p className={styles.packSubTitle}>{pack.book && !pack.isWritingActivity ? 'Continue' : pack.isWritingActivity && pack.book.status > BOOK_STATUS.new ? 'Continue' : 'Setup & Start'}</p>
                </div>
            </div>
        </Link >
    );
}

export default PackListItem;
