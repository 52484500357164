import React from "react";
import { Link } from '@reach/router';
import { itemDateColor } from '../../../services/utils';
import { Book, } from 'react-feather';
import styles from '../Writes.module.scss';
import { BOOK_STATUS } from "@axeedge/go-shared-utils";

const WriteItem = ({ book }) => {

    return (
        <Link to={`/book/${book.id}`} className={`${styles.write} col-lg-4 col-md-6`}>
            <div className='card card-hover'>
                <div className={styles.writeType}>
                    <Book />
                </div>
                <div className={styles.writeContent} style={{ background: itemDateColor(book.createdAt) }}>
                    <h2 className={styles.writeTitle}>{book.bookTemplate?.name}</h2>
                    <p className={styles.writeTitleClass}>{book.studentsClass?.name}</p>
                </div>
                <div className='card-footer'>
                    <p className={styles.writeSubTitle}>{book.status === BOOK_STATUS.completed ? 'View book' : 'Continue'}</p>
                </div>
            </div>
        </Link>
    )
}

export default WriteItem;