import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import { GET_DIGITAL_CLASS_ORDER } from "../../services/graphql";
import { Download, Eye } from "react-feather";
import styles from './DigitalBooks.module.scss';
import bwLogo from '../../../../../../images/logos/boomwriter-full.svg';
import { onDownloadFiles } from "./DownloadBooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DigitalBooks = ({ book }) => {

    const fileUrl = 'TODO';
    const [loadingPdf, setLoadingPdf] = useState(true);
    const [progress, setProgress] = useState('');

    const [numPages, setNumPages] = useState(null);


    const [selectedStudents, setSelectedStudents] = useState([]);
    const { data, error, loading } = useQuery(GET_DIGITAL_CLASS_ORDER, {
        variables: {
            id: book.studentsClass.id
        }
    });


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setLoadingPdf(false);

    };


    const onSelectStudent = e => {
        if (selectedStudents.indexOf(e.target.value) !== -1) {
            setSelectedStudents(selectedStudents.filter(c => c !== e.target.value));
        } else {
            setSelectedStudents([...selectedStudents, e.target.value]);
        }
    }

    const onSelectAllStudents = (e, students) => {
        const selectedIds = students.map(s => s.id);
        e.target.checked ? setSelectedStudents(selectedIds) : setSelectedStudents([])
    }


    //todo: this should contain the actual students books urls
    const fileUrls = [
        ''
    ]


    const renderBook = () => {
        return (
            loadingPdf ? <p>Loading...</p> :
                <Document
                    file={fileUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={() => setLoadingPdf(false)}
                >
                    <HTMLFlipBook width={600} height={600 * 1.4}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <div key={`page_${index + 1}`} > <ReactPdfPage pageNumber={index + 1} /></div>
                        ))}
                    </HTMLFlipBook>
                </Document>
        )
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (data?.studentsClass?.id) {

        const students = data.studentsClass?.students || [];

        return (

            <div className={styles.digital}>
                <div >
                    <div className="panelLight2 u-m-base-3">
                        <h1>{book.bookTemplate?.name}</h1>
                        <p className="u-m-base-2">This book contains the winners of all the chapters voted by your class</p>
                        <div className="u-display-flex u-align-items-center">

                            <button className="btn btn-primary u-m-right-2 u-display-flex u-align-items-center" onClick={() => ''}>Preview <Eye className='u-m-left-1' /></button>
                            <button className="btn btn-primary u-m-right-2 u-display-flex u-align-items-center" onClick={() => ''}>Download <Download className='u-m-left-1' /></button>
                        </div>

                    </div>
                    <button onClick={() => onDownloadFiles(fileUrls, setProgress)}>Test download</button>
                    {progress && <p>{progress}</p>}

                    <h2>Personalized books</h2>
                    <ul className="list-group u-m-base-3">
                        <li className="list-item">
                            <input
                                type="checkbox"
                                className="u-m-right-1"
                                checked={selectedStudents.length === students.length}
                                onChange={(e) => onSelectAllStudents(e, students)}
                            />
                            <p>Select all</p>
                        </li>
                        {students.map(student => (
                            <li key={student.id} className="list-item">
                                <input
                                    type="checkbox"
                                    className="u-m-right-1"
                                    value={student.id}
                                    checked={selectedStudents.indexOf(student.id) > -1}
                                    onChange={(e) => onSelectStudent(e)}
                                />
                                <p>{student.name}</p>
                                <div className="u-ml-auto u-display-flex u-align-items-center">
                                    <button className="u-m-right-2" onClick={() => ''}><Download size={20} /></button>
                                    <button className=" u-text-primary" onClick={() => ''}><Eye size={20} /></button>

                                </div>
                            </li>
                        ))}
                    </ul>
                    <button disabled={selectedStudents.length === 0} className='btn btn-primary u-display-flex u-align-items-center'>Download <Download className='u-m-left-1' /></button>
                </div>
                <div>
                    <div className="panelLight2">

                        <div className={styles.digitalCover}>
                            <img src={book.bookTemplate?.coverUrl} className={styles.digitalCoverImg} />
                            <div className={styles.digitalCoverFooter}>
                                <div>
                                    <p className="bold u-m-base-2">By {data?.studentsClass?.name}</p>
                                    <p className="bold u-m-base-2">{data?.studentsClass?.school?.name}</p>

                                    <p className="bold u-m-base-2">{data?.studentsClass?.school?.country}</p>
                                </div>
                                <div>
                                    <img src={bwLogo} width={200} alt='BW logo' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default DigitalBooks;